import React from 'react';

interface ImageProps {
  imagePath: string;
  alt?: string;
}

const Image: React.FC<ImageProps> = ({ imagePath, alt = "" }) => {
  return (
    <img src={imagePath} alt={alt} className="max-h-[600px]" />
  );
}

export default Image;
