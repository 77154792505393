import React from 'react'

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  color: white;
  width: 100%;      
  flex-direction: column; 
  text-align: center; 
`;

const Concerts = () => {
    return (
        <Container>
            <h3>PRÓXIMOS SHOWS:</h3>
            <div>
                <b>Sábado, 24 de Junho</b>
                <p>Abadon Metal Fest 1</p>
                <b>Cotia, São Paulo, BRASIL</b>
            </div>
        </Container>
    )
}

export default Concerts
