import React from "react";
import { BandLogo } from "../Header/Header.elements";
import { SocialMedia } from "../../components";

const Footer = () => {
  const packageInfo = require("../../../package.json");

  return (
    <div style={{ color: "white", textAlign: "center", padding: "1rem" }}>
      <div className="flex flex-col justify-center items-center relative">
        <img
          alt="Band Logo"
          src="images/vismal_contorno_white.png"
          className="max-w-[350px] sm:max-w-[650px]"
        />
        <img
          alt="Band Logo"
          src="images/vismal_symbol_white.png"
          className="max-w-12 sm:max-w-24 sm:-mt-12 -mt-3"
        />
      </div>
      <SocialMedia />
      <p>vismalmetal@gmail.com</p>
      <p>Last update - 23/12/2024 - Bruno Alvarez</p>
      {/* <p>Package Name: {packageInfo.name}</p> */}
      <p>Version: {packageInfo.version}</p>
    </div>
  );
};

export default Footer;
