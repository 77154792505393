import styled from "styled-components";

export const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  /* padding-right: 1rem;      */
  /* background-color: blue; */
`;

export const TitleCenterDiv = styled.div`
  width: 100%;
  text-align: center;
`;

export const ImageModal = styled.img`
  max-height: 90vh;
  max-width: 90vw;
`;

export const ImageResponsive = styled('img')`
  max-height: 1000px;
  max-width: 1000px;
`;
