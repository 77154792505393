import { useState } from "react";

import "./App.css";
import * as S from "./style";
import { Concerts, Home, Music } from "./pages/index";
import { Header, Footer, Merchandise, Navbar } from "./components";

function App() {
  return (
    <>
      <div className="w-full">
        <Header />
        <div className="container mx-auto max-w-[1350px]">
          <div className="flex flex-col sm:flex-row ">
            <div className="sm:basis-3/4 sm:flex-grow ">
              <Home />
            </div>
            <div className="sm:basis-1/4 sm:flex-grow">
              <Music />
              {/* <Merchandise /> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
