import React, { useState } from "react";
import { FeedContainer, ImageModal } from "./News.elements";
import { FeedItem, Modal } from "../../components";
import { NewsData } from "./News.data";

const News = () => {
  const [news] = useState(NewsData);
  const [modalImage, setModalImage] = useState<string>(
    "images/flyer_08022025.jpeg"
  );
  const [showModal, setShowModal] = useState<boolean>(true);

  const handleOpenImageInModal = (e: any) => {
    setModalImage(e.target.src);
    setShowModal(true);
  };

  return (
    <div className="text-white flex flex-col justify-center items-center pt-3">
      <h3>NOVIDADES:</h3>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          isOpen={true}
          children={<ImageModal src={modalImage} alt="Band picture" />}
        />
      )}
      {news &&
        news.map((item) => {
          return <FeedItem {...item} />;
        })}
    </div>
  );
};

export default News;
