import React from "react";

function Merchandise() {
  return (
    <div className="text-white items-center justify-center flex flex-col">
      <h3>MERCHANDISE:</h3>
      <a
        target="_blank"
        href="https://mojostamp.com.br/camiseta-vismal-1d6ch"
        rel="noreferrer"
      >
        <div className="text-white items-center justify-center flex flex-col space-y-3">
          <img
            src="images/camiseta.jpg"
            alt="T-shirt"
            className="max-w-[300px]"
          />
          <button className="bg-white text-black px-4 py-2 rounded shadow hover:bg-gray-100">
            Comprar camiseta
          </button>
        </div>
      </a>
    </div>
  );
}

export default Merchandise;
