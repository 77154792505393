import React, { useState } from "react";
import {
  Container,
  BandLogo,
  SocialMidiaContainer,
  Link,
} from "./Header.elements";
import { YouTubeBackground } from "./YouTubeBackground";

import { FacebookIcon, InstagranIcon, YoutubeIcon } from "../Icons/Icons";
import { Navbar, SocialMedia } from "../index";
import BackgroundVideo from "./BackgroundVideo";

function Header() {
  const [activeMenu, setActiveMenu] = useState("Home");
  const handleMenuSelect = (menu: string) => {
    setActiveMenu(menu);
  };

  const menuItems = [
    // { text: "home", selected: activeMenu === "home" },
    { text: "videos", selected: activeMenu === "videos" },
    { text: "music", selected: activeMenu === "music" },
    { text: "bio", selected: activeMenu === "bio" },
    { text: "band", selected: activeMenu === "band" },
    { text: "contact", selected: activeMenu === "contact" },
  ];

  return (
    <nav className="flex flex-col justify-center text-center m-0 text-white border-b border-b-[#cccccc] border-solid">
      <BackgroundVideo src="videos/inner_s.mp4">
        <div className="flex flex-col justify-center items-center relative">
          <img alt="Band Logo" src="images/vismal_contorno_white.png" />
          <img
            alt="Band Logo"
            src="images/vismal_symbol_white.png"
            className="max-w-12 sm:max-w-24 sm:-mt-12 -mt-3"
          />
        </div>
        <SocialMedia />
      </BackgroundVideo>
      {/* <Navbar menuItems={menuItems} onMenuSelect={handleMenuSelect} /> */}
    </nav>
  );
}

export default Header;
