import React from 'react';
import styled from 'styled-components';

const VideoBackground = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 30.25%; /* 16:9 aspect ratio (adjust as needed) */
  overflow: hidden;

  @media (max-width: 1100px) {
    padding-bottom: 56.25%; /* Adjust the aspect ratio for smaller screens */
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  font-size: 24px;
  text-align: center;
`;

interface BackgroundVideoProps {
    src: string;
    children: React.ReactNode;
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ src, children }) => (
  <VideoBackground>
    <Video autoPlay loop muted playsInline>
      <source src={src} type="video/mp4" />
    </Video>
    <Content>
      {children}
    </Content>
  </VideoBackground>
);

export default BackgroundVideo;
