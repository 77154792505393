import React from "react";

const musicList = [
  {
    link: "https://www.youtube.com/watch?v=5Xaq5yoaqkE",
    image: {
      src: "images/album_humans.jpg",
      alt: "Humans for sale",
    },
    title: "Humans for sale - SINGLE (2024)",
  },
  {
    link: "https://www.youtube.com/watch?v=w4-y5wX87jA&list=PLHaHoyEs2YfMABw-UPe4_iKQLseEXZcHa",
    image: {
      src: "images/album_inner.jpg",
      alt: "Image 1",
    },
    title: "Inner Suffering (2022)",
  },
  {
    link: "https://www.youtube.com/watch?v=UyKoRy8IPVY&list=PLHaHoyEs2YfMvLsKqWd6v6-dt_m3gSEGz",
    image: {
      src: "images/album_abduction.jpg",
      alt: "Image 2",
    },
    title: "Abduction Now (2009)",
  },
  {
    link: "https://youtu.be/AqnJwqvK-_U",
    image: {
      src: "images/album_rehearsal.png",
      alt: "Lost Tapes",
    },
    title: "Lost Tapes (2003)",
  },
  {
    link: "https://www.youtube.com/watch?v=vL2dMspshQ0&list=PLHaHoyEs2YfN43-NJF4c0e6fovnih9dIX",
    image: {
      src: "images/album_espiritual.jpg",
      alt: "Mundo Espiritural",
    },
    title: "Mundo Espiritual (2003)",
  },
];

const Music = () => {
  return (
    <div className=" flex flex-col items-center text-white sm:max-w-[350px] p-3">
      <h3>MUSIC:</h3>
      <div className="flex-col space-y-3 mt-3 items-center justify-center">
        {musicList.map((item, index) => {
          return (
            <div>
              <a target="_blank" href={item.link} rel="noreferrer">
                <img src={item.image.src} alt={item.image.alt} />
              </a>
              <p className="text-center">{item.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Music;
