import styled from 'styled-components';

export const Nav = styled.nav`
  
`

export const List = styled.ul`      
  display: flex;
  justify-content: center;  
  align-items: center;  
  flex-direction: row;  
  list-style: none;
  padding: 0;
  margin: 0;
`;
 
export const Item = styled.li`
    font-family: 'Ringm', Arial, sans-serif;
    color: white;        
    display: inline;
    font-size: 1.5rem;
    margin: 0.5rem;
    
    @media (max-width: 400px) {          
      font-size: 1.2rem;           
    }
    
    &:hover {
    color: var(--red-blood);    
    cursor:pointer;
  }
`;